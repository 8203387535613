export function convertToBytes(input) {
  const units = {
    kb: 1000,
    mb: 1000 ** 2,
    gb: 1000 ** 3,
    tb: 1000 ** 4,
  }

  const result = input.trim().toLowerCase().match(/^(\d+)\s*([a-z]+)$/);
  if (!result) {
    throw new Error("Invalid format");
  }

  const value = parseInt(result[1], 10); // the numeric part
  const unit = result[2]; // the unit part

  if (units[unit]) {
    return value * units[unit];
  } else {
    throw new Error("Unsupported unit");
  }
}

export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 B';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function nullableInt(input, fallback = null) {
  const n = parseInt(input);
  return Number.isFinite(n) ? n : fallback;
}

export function extractNumber(input) {
  // Remove any character that is not a digit or a period
  const cleanedString = input.replace(/[^0-9.]/g, '');
  // Parse the cleaned string into a floating-point number
  return parseFloat(cleanedString);
}

export function currencyFormat(input, currency = null) {
  const n = Number(input);
  const decimal = Number.isInteger(n) ? 0 : 2;
  let config = { minimumFractionDigits: decimal, maximumFractionDigits: decimal };
  if (currency) {
    config = { ...config, currency, style: 'currency', currencyDisplay: 'code' };
  }
  let formatter = new Intl.NumberFormat('en-US', config);
  return formatter.format(n);
}

export function findIndexOfMax(arr) {
  if (arr.length === 0) return -1;

  let maxIndex = 0;
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > arr[maxIndex]) {
      maxIndex = i;
    }
  }

  return maxIndex;
}